<template>
  <UiSidePanelForm
    size="big"
    :model-value="modelValue"
    :title="ruleToEdit ? 'Edit mapping rule' : 'Create mapping rule'"
    :description="
      ruleToEdit
        ? 'Edit “Mapping rule name comes here” mapping rule.'
        : 'Create new rotation box for Default rotations group.'
    "
    :disabled="loading"
    :primary-button-text="ruleToEdit ? 'Update' : 'Create rule'"
    secondary-button-text="Cancel"
    @update:model-value="$emit('update:modelValue', false)"
    @confirm="submit"
  >
    <form class="h-full" @submit.prevent>
      <UiInputTextField
        v-model="data.name"
        label="Rule name"
        name="name"
        placeholder="Name"
        :error="useGetFieldErrors(v$, ['name'])"
      />
      <div class="my-2 flex w-full flex-col gap-4 py-6">
        <h5 class="text-subhead-1 text-black-60">Parameters</h5>
        <UiInputSearch
          v-if="data.rules?.length && props.ruleToEdit"
          v-model="search"
          :instant="true"
          @search="search = $event"
        />
        <div v-if="data.rules?.length" class="flex flex-1 flex-col gap-2">
          <transition-group name="fade">
            <div v-for="(rule, index) in currentRules" :key="index">
              <UiTagSecondary
                :id="JSON.stringify(rule.id)"
                class="cursor-pointer"
                :active="conditionBeingEdited?.id === rule.id"
                @click="
                  !conditionBeingEdited ? (conditionBeingEdited = cloneDeep(rule)) : (conditionBeingEdited = undefined)
                "
              >
                <template #content>
                  <div>
                    {{ PARAMETERS_NAMES_MAP.get(rule.rule_name) }} {{ rule.rule_operator }} {{ rule.rule_value }}
                    <span class="text-subhead-3 uppercase">{{ rule.union_operator }}</span>
                  </div>
                </template>
              </UiTagSecondary>
              <Collapse :when="conditionBeingEdited?.id === rule.id" class="height-transition">
                <div v-if="conditionBeingEdited" class="mt-4 flex flex-1 flex-col gap-2">
                  <div class="flex flex-row items-stretch justify-between gap-1">
                    <div class="w-10/12">
                      <UiInputSelect
                        v-model="conditionBeingEdited.rule_name"
                        :items="parameterNameItems"
                        label="Parameter Name"
                        name="ParameterName"
                        placeholder="Name"
                      />
                    </div>
                    <div class="w-2/12 min-w-[90px]">
                      <UiInputSelect
                        v-model="conditionBeingEdited.union_operator"
                        :items="unionOperatorItems"
                        label="Operator"
                        name="UnionOperator"
                      />
                    </div>
                  </div>
                  <div class="flex flex-row items-stretch justify-between gap-1">
                    <div class="w-10/12">
                      <UiInputSelect
                        v-model="conditionBeingEdited.rule_value"
                        :items="getParameterValues(conditionBeingEdited)"
                        label="Value"
                        name="name"
                        placeholder="Value"
                        add-new
                        @update:model-value="addCustomValue(conditionBeingEdited, $event)"
                      />
                    </div>
                    <div class="w-2/12 min-w-[90px]">
                      <UiInputSelect
                        v-model="conditionBeingEdited.rule_operator"
                        :items="parameterOperatorItems"
                        label="Logic"
                        name="ParameterOperator"
                      />
                    </div>
                  </div>
                  <div class="mt-2 flex flex-row items-center justify-end gap-4">
                    <UiButtonBase id="delete_parameters" type="secondary" @click="deleteRule(rule)">
                      <UiIcon name="small-close"></UiIcon>
                      Delete parameters
                    </UiButtonBase>
                    <UiButtonBase id="apply" @click="applyRule(rule)">
                      <UiIcon name="check"></UiIcon>
                      Apply
                    </UiButtonBase>
                  </div>
                </div>
              </Collapse>
            </div>
          </transition-group>
        </div>
        <div v-if="newRule" class="flex flex-1 flex-col gap-2">
          <div class="flex flex-row items-stretch justify-between gap-1">
            <div class="w-10/12">
              <UiInputSelect
                v-model="newRule.rule_name"
                :items="parameterNameItems"
                label="Parameter Name"
                name="ParameterName"
                placeholder="Name"
              />
            </div>
            <div class="w-2/12 min-w-[90px]">
              <UiInputSelect
                v-model="newRule.union_operator"
                :items="unionOperatorItems"
                label="Operator"
                name="UnionOperator"
              />
            </div>
          </div>
          <div class="flex flex-row items-stretch justify-between gap-1">
            <div class="w-10/12">
              <UiInputSelect
                v-model="newRule.rule_value"
                :items="getParameterValues(newRule)"
                label="Value"
                name="name"
                placeholder="Value"
                add-new
                @update:model-value="addCustomValue(newRule, $event)"
              />
            </div>
            <div class="w-2/12 min-w-[90px]">
              <UiInputSelect
                v-model="newRule.rule_operator"
                :items="parameterOperatorItems"
                label="Logic"
                name="ParameterOperator"
              />
            </div>
          </div>
          <div class="mt-2 flex flex-row items-center justify-end gap-4">
            <UiButtonBase id="delete_parameters" type="secondary" @click="newRule = undefined">
              <UiIcon name="small-close"></UiIcon>
              Delete parameters
            </UiButtonBase>
            <UiButtonBase id="apply" @click="data.rules?.push(newRule), (newRule = undefined)">
              <UiIcon name="check"></UiIcon>
              Apply
            </UiButtonBase>
          </div>
        </div>
        <UiButtonGhost id="add_condition" :disabled="newRule" @click="addRule">
          <UiIcon name="big-add-circle"></UiIcon>
          Add condition
        </UiButtonGhost>
      </div>
      <div class="mb-8">
        <h5 class="text-subhead-1 mb-4 text-black-60">Rotation group</h5>
        <UiInputRadio
          id="is_default"
          v-model="rotationGroup"
          :items="groupItems"
          class="mb-4"
          vertical
          @update:model-value="updateRotationItems"
        />
        <UiInputSelect
          v-model="data.rotation_id"
          :items="rotationItems"
          :disabled="rotationGroup === GROUPS.POOL"
          label="Rotation group"
          name="RotationGroup"
          placeholder="Rotation group"
          :error="useGetFieldErrors(v$, ['rotation_id'])"
          @update:model-value="setRotationLanguageId"
        />
      </div>
      <div>
        <h5 class="text-subhead-1 mb-4 text-black-60">Update incoming leads</h5>
        <UiInputSelect
          :model-value="rotationGroup === GROUPS.DEFAULT ? undefined : data.language_id"
          :items="languagesItems"
          :disabled="rotationGroup === GROUPS.DEFAULT"
          label="Language"
          name="Language"
          :placeholder="rotationGroup === GROUPS.DEFAULT ? 'Default language in rotation' : 'English'"
          @update:model-value="data.language_id = $event"
        />
        <Transition name="fade" mode="out-in">
          <p v-show="rotationGroup === GROUPS.DEFAULT" class="text-caption-2 ml-4 mt-0.5 text-black-80">
            You can't change the language for the default group, it is set automatically.
          </p>
        </Transition>
      </div>
    </form>
  </UiSidePanelForm>
</template>

<script setup lang="ts">
import cloneDeep from 'lodash/cloneDeep'
import { Collapse } from 'vue-collapsed'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import type { InputItem, Mapping, MappingRule, Rotation, User } from '@/types'
import { PARAMETERS_NAMES_MAP } from '@/constants'
import { useUiStore } from '@/store/ui'

const uiStore = useUiStore()

const GROUPS = {
  DEFAULT: 'Default',
  CUSTOM: 'Custom',
  POOL: 'Pool',
}

const ENGLISH_LANGUAGE_ID = 40

const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
  ruleToEdit?: Mapping
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  ruleToEdit: undefined,
})

const groupItems = [
  { text: 'Default group', value: GROUPS.DEFAULT },
  { text: 'Custom group', value: GROUPS.CUSTOM },
  { text: 'Lead pool', value: GROUPS.POOL },
]

type MappedRotation = {
  value: number
  text: string
}

const parameterOperatorItems = ref([])
const unionOperatorItems = ref([])
const parameterNameItems = ref<InputItem[]>([])
const allParameters = ref([])
const defaultRotationGroups = ref<(MappedRotation & { language: { id: number; name: string } })[]>([])
const customRotationGroups = ref<MappedRotation[]>([])
const customValues = ref({
  agentsItems: [] as InputItem[],
  languagesItems: [] as InputItem[],
  countryItems: [] as InputItem[],
})
const loading = ref(false)
const search = ref('')
const newRule = ref()
const rotationGroup = ref(props.ruleToEdit ? props.ruleToEdit.rotation.type : GROUPS.DEFAULT)
const data = ref<Partial<Mapping>>(
  props.ruleToEdit
    ? JSON.parse(
        JSON.stringify({
          ...props.ruleToEdit,
          rotation_id: props.ruleToEdit.rotation?.type === GROUPS.POOL ? undefined : props.ruleToEdit.rotation?.id,
          language_id: props.ruleToEdit?.language?.id,
          tag_ids: props.ruleToEdit.tags.map((t) => t.id),
        })
      )
    : {
        name: '',
        rules: [],
        rotation_id: undefined,
        language_id: undefined,
        tag_ids: [],
      }
)
const conditionBeingEdited = ref<MappingRule>()
const poolRotationGroupId = ref()

const currentRules = computed(() => data.value.rules?.filter((r) => r.rule_name.toLowerCase().includes(search.value)))

const rotationItems = computed(() => {
  return rotationGroup.value === GROUPS.DEFAULT ? defaultRotationGroups.value : customRotationGroups.value
})

const languagesItems = computed(() => customValues.value.languagesItems.map((i) => ({ text: i.text, value: i.id })))

onNuxtReady(
  async () =>
    await Promise.all([
      getOperators(),
      getAgents(),
      getLanguages(),
      getCountries(),
      getParameters(),
      getDefaultRotationGroups(),
      getCustomRotationGroups(),
      getLeadRotationsGroups(),
    ])
)

const getParameters = async () => {
  const response = await useGetRotationsParameters()
  parameterNameItems.value = Object.entries(response.data).map((i) => ({
    value: i[0],
    text: PARAMETERS_NAMES_MAP.get(i[0]),
  }))

  allParameters.value = toRaw(response.data)
}

const getParameterValues = (rule: MappingRule) => {
  const parametersWithCustomValues = {
    language_iso: 'languagesItems',
    country_iso: 'countryItems',
    agent_id: 'agentsItems',
  }

  if (!rule.rule_name || !allParameters.value[rule.rule_name]) return []
  if (!parametersWithCustomValues[rule.rule_name] && allParameters.value[rule.rule_name]) {
    return allParameters.value[rule.rule_name].filter((i) => i).map((i) => ({ value: i, text: i }))
  }

  return customValues.value[parametersWithCustomValues[rule.rule_name]]
}

const addCustomValue = (rule: MappingRule, value: string) => {
  if (!allParameters.value[rule.rule_name].includes(value)) allParameters.value[rule.rule_name].push(value)
}

const getOperators = async () => {
  const response = await useGetRotationsParametersOperators()
  parameterOperatorItems.value = response.data.parameter.map((i) => ({ value: i, text: i }))
  unionOperatorItems.value = response.data.union.map((i) => ({ value: i, text: i }))
}

const getDefaultRotationGroups = async () => {
  const response = await useGetRotationGroups('default')
  defaultRotationGroups.value = response.data.map((i: Rotation) => ({
    value: i.id as number,
    text: i.name,
    language: {
      id: i.language.id,
      name: i.language.name,
    },
  }))
}

const getCustomRotationGroups = async () => {
  const response = await useGetRotationGroups('custom')
  customRotationGroups.value = response.data.map((i: Rotation) => ({ value: i.id as number, text: i.name }))
}

const getLeadRotationsGroups = async () => {
  const response = await useGetRotationGroups('pool')
  if (response.data.length) poolRotationGroupId.value = response.data[0].id
}

const getAgents = async () => {
  const users = await useGetUsers()
  customValues.value.agentsItems = users.map((a: User) => ({ value: a.id, text: a.name }))
}

const getLanguages = async () => {
  const languagesList = await useLanguages()
  customValues.value.languagesItems = languagesList.map((l) => ({
    text: l.name,
    value: l.code.toUpperCase(),
    id: l.id,
  }))
}

const getCountries = async () => {
  const countries = await useCountries()
  customValues.value.countryItems = countries.map((c) => ({ text: c.name, value: c.iso2 }))
}

const rules = computed(() => ({
  name: { required: helpers.withMessage('Name is required', required) },
  rules: { required: helpers.withMessage('At least one rule is required', required) },
  rotation_id: { required: helpers.withMessage('Rotation group is required', required) },
}))

const v$ = useVuelidate(rules, data)

const addRule = () => {
  newRule.value = {
    rule_name: '',
    rule_operator: '=',
    rule_value: '',
    union_operator: 'and',
    priority: (data.value.rules?.length || 0) + 1,
  }
}

const deleteRule = (rule: MappingRule) => {
  const index = Number(data.value.rules?.findIndex((r) => r.id === rule.id))
  if (index >= 0) {
    data.value.rules?.splice(index, 1)
    conditionBeingEdited.value = undefined
  }
}

const applyRule = (rule: MappingRule) => {
  const index = Number(data.value.rules?.findIndex((r) => r.id === rule.id))
  if (index >= 0) {
    data.value.rules?.splice(index, 1, conditionBeingEdited.value)
    conditionBeingEdited.value = undefined
  }
}

const updateRotationItems = () => {
  data.value.rotation_id = undefined

  if (rotationGroup.value === GROUPS.POOL || rotationGroup.value === GROUPS.CUSTOM) {
    setEnglishLanguageId()
  }

  v$.value.$reset()
}

const setRotationLanguageId = (rotationId?: number) => {
  if (rotationGroup.value === GROUPS.DEFAULT) {
    const rotationLanguage = defaultRotationGroups.value.find(({ value: id }) => id === rotationId)

    data.value.language_id = rotationLanguage?.language.id
  }
}

const setEnglishLanguageId = () => {
  const englishLanguage = languagesItems.value.find(({ value: id }) => id === ENGLISH_LANGUAGE_ID)

  data.value.language_id = englishLanguage?.value
}

const submit = async () => {
  if (rotationGroup.value === GROUPS.POOL) {
    data.value.rotation_id = poolRotationGroupId.value
  }

  const isValid = await v$.value.$validate()

  if (isValid) {
    loading.value = true
    try {
      if (props.ruleToEdit) {
        data.value.rules?.forEach((r, index) => {
          r.priority = index + 1
        })
        await useEditMapping(data.value)
        emits('input', data.value)
      } else {
        const newMapping = await useCreateMapping(data.value)

        emits('input', { ...data.value, id: newMapping.id })
      }
      emits('update:modelValue', false)
    } catch (err) {
      uiStore.showSnackBanner(err.message, 'error')
    } finally {
      loading.value = false
    }
  }
}
</script>

<style scoped></style>
